@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Poppins&family=Ubuntu:wght@500&display=swap");

.dashboard{
  background-color: #e0e0e0;
  height: 100vh;
  
}
.btn-logout {
  background-color: #ff3300;
  padding: 10px 20px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 5px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}
.title-dash{
  color: #323232;
  font-family: 'Ubuntu',sans-serif;
  position: absolute;
  top: 10px;
  left: 10px;
}
.btn-logout:hover {
  opacity: 0.5;
  cursor: pointer;
}
.dashFlex{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
}
.dash-card-list{
  color: #212121;
  font-family: 'Poppins',sans-serif;
}
.dash-card-text{
  font-family: 'Poppins',sans-serif;
}
.dash-card-title{
  font-family: 'Ubuntu',sans-serif;
}
.dashCard{
  border-radius: 50px;
  padding: 40px;
background: #e0e0e0;
box-shadow:  20px 20px 60px #bebebe,-20px -20px 60px #ffffff;
margin: 5px;
max-width: 600px;
}