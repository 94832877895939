@import url(https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Poppins&family=Ubuntu:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Poppins&family=Ubuntu:wght@500&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: #e0e0e0;
}
.container-flex-centered {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #e0e0e0;
}
.logincard {
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  padding: 100px;
}
.login-logo{
  width: 10%;
}
.logincard h1 {
  color: #323232;
  font-family: "Ubuntu", sans-serif;
  text-align: center;
  margin: 10px;
}
.form-group {
  margin: 10px;
}
.btn-group {
  display: flex;
  justify-content: center;
}
.btn {
  background-color: #ff6600;
  padding: 10px 20px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 5px;
  text-decoration: none;
  color: #ffffff;
}
.btn:hover {
  opacity: 0.5;
  cursor: pointer;
}
.form-group .textbox {
  padding: 5px 7px;
  outline: none;
  border: none;
  border-radius: 50px;
  background: #e0e0e0;
  font-family: 'Poppins',sans-serif;
  box-shadow: inset 20px 20px 60px #bebebe, inset -20px -20px 60px #ffffff;
  width: 100%;
}
.link{
    color: #212121;
    font-family: 'Poppins',sans-serif;
    text-align: center;
    text-decoration: underline;
}
@media (max-width: 320px) {
  .logincard {
    padding: 10px;
  }
}
@media (max-width: 375px) {
  .logincard {
    padding: 20px;
  }
}


.dashboard{
  background-color: #e0e0e0;
  height: 100vh;
  
}
.btn-logout {
  background-color: #ff3300;
  padding: 10px 20px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 5px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}
.title-dash{
  color: #323232;
  font-family: 'Ubuntu',sans-serif;
  position: absolute;
  top: 10px;
  left: 10px;
}
.btn-logout:hover {
  opacity: 0.5;
  cursor: pointer;
}
.dashFlex{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
}
.dash-card-list{
  color: #212121;
  font-family: 'Poppins',sans-serif;
}
.dash-card-text{
  font-family: 'Poppins',sans-serif;
}
.dash-card-title{
  font-family: 'Ubuntu',sans-serif;
}
.dashCard{
  border-radius: 50px;
  padding: 40px;
background: #e0e0e0;
box-shadow:  20px 20px 60px #bebebe,-20px -20px 60px #ffffff;
margin: 5px;
max-width: 600px;
}
