@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Poppins&family=Ubuntu:wght@500&display=swap");
.container-flex-centered {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #e0e0e0;
}
.logincard {
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  padding: 100px;
}
.login-logo{
  width: 10%;
}
.logincard h1 {
  color: #323232;
  font-family: "Ubuntu", sans-serif;
  text-align: center;
  margin: 10px;
}
.form-group {
  margin: 10px;
}
.btn-group {
  display: flex;
  justify-content: center;
}
.btn {
  background-color: #ff6600;
  padding: 10px 20px;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 5px;
  text-decoration: none;
  color: #ffffff;
}
.btn:hover {
  opacity: 0.5;
  cursor: pointer;
}
.form-group .textbox {
  padding: 5px 7px;
  outline: none;
  border: none;
  border-radius: 50px;
  background: #e0e0e0;
  font-family: 'Poppins',sans-serif;
  box-shadow: inset 20px 20px 60px #bebebe, inset -20px -20px 60px #ffffff;
  width: 100%;
}
.link{
    color: #212121;
    font-family: 'Poppins',sans-serif;
    text-align: center;
    text-decoration: underline;
}
@media (max-width: 320px) {
  .logincard {
    padding: 10px;
  }
}
@media (max-width: 375px) {
  .logincard {
    padding: 20px;
  }
}

